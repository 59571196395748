<template>
  <div class="information">
    <!-- 新闻页版心 -->
    <!-- src="@/assets/img/information/img-information-bgm.png" -->
    <div class="slideshow" v-for="(item, index) in banner" :key="index" v-show="index == 0">
      <img :src="$utils.picturePrefix(item.image)" alt="" />
    </div>
    <div class="information-body">
      <div class="information-body-left">
        <el-tabs v-model="leftTabId" @tab-click="handleClick">
          <el-tab-pane
            v-for="(item, i) in leftTabs"
            :key="i"
            :label="item.name"
            :name="item.id.toString()"
          >
            <div class="information-list" v-show="list.length > 0">
              <div
                class="item"
                @click="go_detail(item.id)"
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="item-img">
                  <img :src="$utils.picturePrefix(item.image)" />
                </div>
                <div class="item-body">
                  <div class="title ellipsis_two">
                    {{ item.title }}
                  </div>
                  <div class="buttom">
                    <div class="watch-amount">
                      <span class="iconfont icon-yanjing"></span>
                      <span class="watch-amount-num">
                        {{ item.views || 0 }}
                      </span>
                    </div>
                    <div class="time">{{ item.createDate }}</div>
                  </div>
                </div>
              </div>
            </div>
            <empty
              v-show="!list || list.length <= 0"
              description="暂无新闻"
            ></empty>
            <pagination :total="total" @change="pagin_change" v-if="total != 0"></pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="information-body-right">
        <div class="list-title">
          {{ rigthTab.name }}
        </div>
        <div class="information-list">
          <div
            class="item"
            v-for="(item, index) in journalism_list"
            :key="index"
            @click="go_detail(item.id)"
          >
            <div class="title ellipsis_two">
              {{ item.title }}
            </div>
            <div class="item-img">
              <img :src="$utils.picturePrefix(item.image)" />
            </div>
            <div class="buttom">
              <div class="watch-amount">
                <span class="iconfont icon-yanjing"></span>
                <span class="watch-amount-num">
                  {{ item.views || 0 }}
                </span>
              </div>
              <div class="time">{{ item.createDate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
// import { session } from "@/utils/sessions";
export default {
  components: {
    Pagination,
    Empty
  },
  data() {
    return {
      leftTabs: [],
      leftTabId: "",
      rigthTab: {},
      banner: [],
      loading: false,
      item: "",
      image: "",
      total: 0,
      tab_index: 0,
      rigth_tab_index: 0,
      type: 4,
      //  新闻类型
      detail: [],
      //  新闻页中间展示数据
      list: [],
      //  新闻页右侧新闻展示数据
      journalism_list: [],
      num: 10,
      page: 1,
      pageHide: false,
      rigth_typeId: ""
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getConsultingType();
    this.getConsultingTypeSide();
    this.getBanner();
  },
  methods: {
    bannerHeight() {
        let width = window.innerWidth;
        let getClass = document.getElementsByClassName("slideshow")[0];
        
        // eslint-disable-next-line no-self-assign
        width < 1200 ? (width = 1200) : (width = width);
        let height = width / 1920 * 360;
        console.log(getClass,height);
        return getClass.style.height = height + "px";
    },
    handleClick() {
      this.page = 1;
      this.getConsulting();
    },
    //  @click 点击后跳转至新闻详情页
    go_detail(id) {
      // this.$router.push(`/information/detail?id=${id}`);
      // session.set_informationList(this.list);
      let routeData = this.$router.resolve({
        path: `/information/detail?id=${id}`,
      });
      window.open(routeData.href, "_blank");
    },

    //  背景图
    getBanner() {
      this.$api.getBanner({ type: this.type }).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.banner = data;
          setTimeout(() => {
            this.bannerHeight();
          }, 0);
        }
      });
    },
    // 左侧（新闻类型）tab 栏获取
    getConsultingType() {
      this.$api.getConsultingType().then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.leftTabs = data;
          if (data.length > 0) {
            this.leftTabId = data[0].id.toString();
          }

          this.getConsulting();
        }
      });
    },
    // 右侧tab 栏获取
    getConsultingTypeSide() {
      this.$api.getConsultingTypeSide().then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.rigthTab = data;
          this.getConsultingRight();
        }
      });
    },
    // tab 栏切换
    tab_handel(id, index) {
      this.loading = true;
      this.tab_index = index;
      this.typeId = id;
      this.getConsulting();
    },

    // 平台咨讯 列表
    getConsulting() {
      this.$api
        .getConsulting({
          num: this.num,
          page: this.page,
          typeId: this.leftTabId
        })
        .then(res => {
          let {
            code,
            data: { records, total }
          } = res;

          if (code == 200) {
            this.loading = false;
            this.total = total;
            this.list = records;
          }
        });
    },
    // 右侧 列表
    getConsultingRight() {
      this.$api
        .getConsulting({ num: 5, page: 1, typeId: this.rigthTab.id })
        .then(res => {
          let {
            code,
            data: { records }
          } = res;

          if (code == 200) {
            this.journalism_list = records;
          }
        });
    },
    // 分页
    pagin_change(page, pageSize) {
      this.page = page;
      this.num = pageSize;
      this.getConsulting();
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
